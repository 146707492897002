import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';
import { graphql, useStaticQuery } from "gatsby"

import MapImg from '../../assets/images/map.png'
import Shape1 from '../../assets/images/shape1.png'
import Shape2 from '../../assets/images/shape2.svg'

const query = graphql`
{
    strapiSiteLogo{
      id
      blackLogo{
        id
        name
        url
      }
      whiteLogo{
        id
        name
        url
      }
    }
  }
`

const Footer = () => {

    const data = useStaticQuery(query)
    const {strapiSiteLogo: {blackLogo, whiteLogo}} = data

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <Link to="/">
                                    <img 
                                        src={blackLogo.url} 
                                        alt="Logo"
                                    />
                                </Link>
                            </div>
                            
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Company</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/about-us">
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/features">
                                        Features
                                    </Link>
                                </li>
                                
                                
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Support</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="terms-condition">
                                        Terms & Condition
                                    </Link>
                                </li>
                                
                                <li>
                                    <Link to="/contact">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>
                            
                            <ul className="footer-contact-info">
                                <li> 
                                    <Icon.MapPin />
                                    Borgarfjordsgatan 21, Stockholm, <br /> Kista 164 53, Sweden
                                </li>
                                <li>
                                    <Icon.Mail />
                                    Email: <Link to="mailto:developer@codeofduty.se">developer@codeofduty.se</Link>
                                </li>
                                {/* <li> 
                                    <Icon.PhoneCall />
                                    Phone: <Link to="tel:321984754">+ (321) 984 754</Link>
                                </li> */}
                            </ul>

                            {/* <ul className="social-links">
                                <li>
                                    <a href="https://www.facebook.com/" className="facebook" target="_blank" rel="noreferrer"><Icon.Facebook /></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" className="twitter" target="_blank" rel="noreferrer"><Icon.Twitter /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" className="instagram" target="_blank" rel="noreferrer"><Icon.Instagram /></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" className="linkedin" target="_blank" rel="noreferrer"><Icon.Linkedin /></a>
                                </li>
                            </ul> */}
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright &copy; {currentYear} Spet. All rights reserved by <a href="https://codeofduty.se/" target="_blank">CodeOfDuty AB</a></p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </footer>
    )
     
}

export default Footer; 